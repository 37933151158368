import React, { FunctionComponent } from 'react';
import { Container, Info, NoWrap } from './style';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
export const Footer: FunctionComponent<{}> = () => {
    return (
        <Container>
            <Info>
                <NoWrap>&copy; {new Date().getUTCFullYear()}</NoWrap>{' '}
                <NoWrap>Diederik De Coninck</NoWrap>{' '}
                <a
                    href={'https://www.linkedin.com/in/diederikdeconinck/'}
                    style={{ color: 'white' }}
                >
                    <LinkedInIcon />
                </a>
            </Info>
            {/*<Info>Our website uses no cookies :)</Info>*/}
        </Container>
    );
};
