import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import HamburgerMenu from './HamburgerMenu';
import LanguageIcon from '@material-ui/icons/Language';
import styled from 'styled-components';
import { maxWidth, media } from '../style';

const HeaderContainer = styled.header`
    max-width: ${maxWidth};
    padding: 1rem;
    ${media({ xs: 'padding: 0.3rem 0.3rem;' })}
    position: absolute;
    font-weight: 300;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
`;
export const Header = ({ siteTitle }: any) => {
    const { t, originalPath, language } = useI18next();

    return (
        <HeaderContainer className="main-header">
            <h1 style={{ margin: 0 }}>{siteTitle}</h1>
            <Link
                to={originalPath}
                style={{
                    color: 'white',
                    textDecoration: 'none',
                    display: 'flex', // Enable flexbox layout
                    alignItems: 'center', // Vertically centers items within the flex container
                    float: 'right', // Keep the link floated to the right
                }}
            >
                {language.toUpperCase()}&nbsp;
                <LanguageIcon />
            </Link>
            <HamburgerMenu
                data={{
                    emergency: t('Emergency'),
                    contact: t('Contact'),
                    about: t('Orthodontist'),
                    orthodontics: t('Orthodontics'),
                }}
            />
        </HeaderContainer>
    );
};
